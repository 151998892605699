import React from 'react';
import { FaHammer, FaPaintRoller, FaPlug, FaWater, FaHome, FaTruck, FaBuilding, FaDraftingCompass } from 'react-icons/fa';

const Services = () => {
    return (
        <section id="services" className="py-10 px-4 bg-gray-100">
            <div className="container mx-auto">
                <h2 className="text-4xl font-bold mb-8 text-center text-gray-800">Jakie usługi budowlane wykonujemy?</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                    <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                        <FaHammer className="text-4xl text-green-500 mb-4 mx-auto" />
                        <p className="text-gray-700">Usługi remontowe, takie jak prace wyburzeniowe, wylewki, tynkowanie, szpachlowanie itp.</p>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                        <FaPaintRoller className="text-4xl text-green-500 mb-4 mx-auto" />
                        <p className="text-gray-700">Wykończenia wnętrz, czyli malowanie, układanie płytek, paneli, sufitów podwieszanych, zabudowy g-k i inne drobne prace</p>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                        <FaPlug className="text-4xl text-green-500 mb-4 mx-auto" />
                        <p className="text-gray-700">Montaż instalacji elektrycznych, a także pomiary ochronne, ogrzewania elektryczne, czy założenie sieci teletechnicznych/komputerowych</p>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                        <FaWater className="text-4xl text-green-500 mb-4 mx-auto" />
                        <p className="text-gray-700">Usługi hydrauliczne, takie jak wykonanie nowych instalacji, modernizacja starych instalacji, wykonanie pionów kanalizacyjnych i wiele więcej</p>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                        <FaHome className="text-4xl text-green-500 mb-4 mx-auto" />
                        <p className="text-gray-700">Budowa domów w zakresie przewidzianym przez klienta – od stanu surowego otwartego, aż po stan deweloperski</p>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                        <FaTruck className="text-4xl text-green-500 mb-4 mx-auto" />
                        <p className="text-gray-700">Prace ziemne przy wykorzystaniu nowoczesnych maszyn budowlanych</p>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                        <FaBuilding className="text-4xl text-green-500 mb-4 mx-auto" />
                        <p className="text-gray-700">Elewacje wentylowane i niewentylowane</p>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                        <FaDraftingCompass className="text-4xl text-green-500 mb-4 mx-auto" />
                        <p className="text-gray-700">Projekty mieszkań i domów we współpracy z biurem architektonicznym</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Services;
