import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';

const About = () => {
    return (
        <section id="about" className="container mx-auto py-10 px-4">
            <h2 className="text-4xl font-bold mb-8 text-center text-gray-800">Kompleksowe remonty Gdańsk – nowoczesne wnętrza w Twoim domu</h2>
            <div className="flex flex-col md:flex-row items-center">
                <div className="md:w-1/2 mb-8 md:mb-0">
                    <img src="https://detali.tomsk.ru/assets/images/portfolio/kvartiry/4-komnaty-kvartira-mimumalizm-2024/1-gostinaya-kyhnya-prihojaya/5.jpg" alt="About Us" className="rounded-lg shadow-lg" />
                </div>
                <div className="md:w-1/2 md:pl-8">
                    <p className="text-lg text-gray-700 mb-4">
                        Przeprowadzimy remont Twojego mieszkania od A do Z. Wybierając nasze usługi, możesz liczyć na profesjonalną obsługę na każdym etapie współpracy – począwszy od opracowania projektu, przez realizację prac budowlanych, aż po kompleksowe wykończenie wnętrz.
                    </p>
                    <p className="text-lg text-gray-700 mb-4">
                        Działamy w Trójmieście i okolicach. Jesteśmy otwarci na kontakt i Twoje potrzeby. Kontaktując się z klientem zawsze informujemy o rozwoju i stanie prac budowlanych. Zadbamy o to, aby efekt końcowy był dokładnie taki, jaki sobie wyobraziłeś.
                    </p>
                    <div className="flex items-center text-green-500 mb-2">
                        <FaCheckCircle className="mr-2" />
                        <span className="text-lg">Profesjonalna obsługa</span>
                    </div>
                    <div className="flex items-center text-green-500 mb-2">
                        <FaCheckCircle className="mr-2" />
                        <span className="text-lg">Realizacja od A do Z</span>
                    </div>
                    <div className="flex items-center text-green-500 mb-2">
                        <FaCheckCircle className="mr-2" />
                        <span className="text-lg">Kompleksowe wykończenie wnętrz</span>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;
