import React from 'react';
import Navbar from './components/Navbar';
import FeaturesSection from './components/FeaturesSection';
import About from "./components/About";
import Services from "./components/Services";
import Home from "./components/Home";
import CombinedSection from "./components/CombinedSection";
import Testimonials from "./components/Testimonials";
import Contact from "./components/Contact";

function App() {
    return (
        <div className="App">
            <Navbar />
            <main>
                <Home />
                <About />
                <Services />
                <CombinedSection/>
                <FeaturesSection />
                <Testimonials/>
                <Contact/>
            </main>
            {/*<Footer/>*/}
        </div>
    );
}

export default App;
