import React, { useState } from 'react';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <header className="sticky top-0 bg-white shadow z-50">
            <nav className="container mx-auto p-4 flex justify-between items-center">
                <div className="text-lg font-bold">DAREX</div>
                <div className="md:hidden">
                    <button onClick={toggleMenu} className="text-black focus:outline-none">
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                        </svg>
                    </button>
                </div>
                <ul className="hidden md:flex space-x-4">
                    <li className="py-2 md:py-0"><a href="#contact">Kontakt</a></li>
                    <li className="py-2 md:py-0"><a href="#offer">Oferta</a></li>
                    <li className="py-2 md:py-0"><a href="#portfolio">Portfolio</a></li>
                </ul>
                <button className="hidden md:block bg-black text-white py-2 px-4">Zadzwoń</button>
            </nav>
            {isOpen && (
                <div className="md:hidden bg-white shadow-md absolute top-full left-0 right-0 z-50">
                    <ul className="flex flex-col items-center space-y-2 py-4">
                        <li><a href="#contact" className="block py-2 px-4 text-black">Kontakt</a></li>
                        <li><a href="#offer" className="block py-2 px-4 text-black">Oferta</a></li>
                        <li><a href="#portfolio" className="block py-2 px-4 text-black">Portfolio</a></li>
                    </ul>
                    <button className="block w-full bg-black text-white py-2">Zadzwoń</button>
                </div>
            )}
        </header>
    );
};

export default Navbar;
