import React from 'react';

const Testimonials = () => {
    return (
        <section id="testimonials" className="py-10 px-4">
            <div className="container mx-auto text-center">
                <h2 className="text-3xl font-bold text-gray-800 mb-8">Co mówią nasi klienci?</h2>
                <div className="flex flex-col md:flex-row justify-center items-center gap-8">
                    <div className="max-w-sm p-4 bg-white rounded-lg shadow-lg">
                        <p className="text-gray-700 mb-4">"DAREX przekroczyło nasze oczekiwania! Wykończenie wnętrz jest piękne i funkcjonalne. Polecamy każdemu!"</p>
                        <p className="font-bold text-gray-800">- Anna Kowalska</p>
                    </div>
                    <div className="max-w-sm p-4 bg-white rounded-lg shadow-lg">
                        <p className="text-gray-700 mb-4">"Profesjonalna obsługa i terminowa realizacja. Jesteśmy bardzo zadowoleni z usług DAREX."</p>
                        <p className="font-bold text-gray-800">- Jan Nowak</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Testimonials;
