import React, { useEffect, useRef, useState } from 'react';

const Home = () => {
    const contentRef = useRef(null);
    const [minHeight, setMinHeight] = useState('auto');

    useEffect(() => {
        if (contentRef.current) {
            setMinHeight(`${contentRef.current.clientHeight}px`);
        }
    }, []);

    return (
        <section
            id="home"
            className="bg-cover bg-center relative overflow-hidden"
            style={{
                backgroundImage: 'url(https://detali.tomsk.ru/assets/images/portfolio/kvartiry/4-komnaty-kvartira-mimumalizm-2024/1-gostinaya-kyhnya-prihojaya/16.jpg)',
                minHeight: `calc(${minHeight} + 64px)`,
            }}
        >
            <div className="absolute inset-0 flex items-center justify-center md:justify-start overflow-hidden">
                <div
                    ref={contentRef}
                    className="bg-white bg-opacity-90 p-6 md:p-10 rounded-lg shadow-lg text-left mx-4 md:ml-8 max-w-md overflow-y-auto"
                >
                    <h1 className="text-2xl md:text-4xl font-extrabold text-black mb-6">
                        Twoje Marzenie o Perfekcyjnym Wnętrzu
                    </h1>
                    <p className="text-lg md:text-xl text-black mb-4">
                        Planujesz remont lub wykończenie wnętrza? My zajmiemy się wszystkim:
                    </p>
                    <ul className="list-disc list-inside text-lg md:text-xl text-black mb-6 space-y-2">
                        <li>Indywidualny projekt wnętrza</li>
                        <li>Kompleksowa lista zakupów</li>
                        <li>Zamówienie i dostawa produktów</li>
                        <li>Koordynacja prac wykończeniowych</li>
                        <li>Szybki i terminowy remont</li>
                        <li>Gwarantowany perfekcyjny efekt</li>
                    </ul>
                    <p className="text-lg md:text-xl text-black mb-6">
                        Zaufaj naszym ekspertom i ciesz się pięknym wnętrzem bez stresu!
                    </p>
                    <div className="flex justify-center md:justify-start">
                        <button className="mt-2 px-6 py-3 md:px-8 md:py-4 bg-blue-600 text-white hover:bg-blue-700 rounded text-lg md:text-xl font-semibold">
                            ZADZWOŃ TERAZ
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Home;
