import React from 'react';
import { FaAward, FaGem, FaSmile, FaClock } from 'react-icons/fa';

const FeaturesSection = () => {
    return (
        <section id="features" className="py-10 bg-gray-100">
            <div className="container mx-auto text-center">
                <h2 className="text-3xl font-bold mb-8">Dlaczego warto nam zaufać?</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                    <div className="bg-white p-6 rounded-md shadow-lg">
                        <FaAward className="text-4xl mx-auto" />
                        <h3 className="text-xl font-bold mt-4">Gwarancja terminu + Rabat 7% na materiały wykończeniowe</h3>
                        <p className="mt-2 text-sm">Zapewniamy, że prace w Twoim mieszkaniu rozpoczną i zakończą się w wyznaczonym terminie. Otrzymasz od nas rabat 7% na wszystkie materiały wykończeniowe.</p>
                    </div>
                    <div className="bg-white p-6 rounded-md shadow-lg">
                        <FaGem className="text-4xl mx-auto" />
                        <h3 className="text-xl font-bold mt-4">350+ wykonanych realizacji oraz zaufane i niezawodne ekipy wykończeniowe</h3>
                        <p className="mt-2 text-sm">Nasze usługi wykonywane są przez sprawdzonych i zaufanych fachowców, przy użyciu sprawdzonych materiałów.</p>
                    </div>
                    <div className="bg-white p-6 rounded-md shadow-lg">
                        <FaSmile className="text-4xl mx-auto" />
                        <h3 className="text-xl font-bold mt-4">350+ zadowolonych klientów</h3>
                        <p className="mt-2 text-sm">Nasi Koordynatorzy weryfikują pod względem technicznym (normy), zgodności z projektem, dostaw produktów i materiałów na budowę.</p>
                    </div>
                    <div className="bg-white p-6 rounded-md shadow-lg">
                        <FaClock className="text-4xl mx-auto" />
                        <h3 className="text-xl font-bold mt-4">Zespół czuwający nad przebiegiem prac</h3>
                        <p className="mt-2 text-sm">Przy wykończeniu wnętrza, będziesz mógł zająć się swoimi sprawami. Nasz Zespół: Projektant, Logistyk przejmie wszystkie obowiązki związane z pracami nad projektem.</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FeaturesSection;
