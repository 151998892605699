import React from 'react';

const Contact = () => {
    return (
        <section id="contact" className="container mx-auto py-10 px-4 bg-gray-50">
            <h2 className="text-4xl font-bold mb-12 text-center text-blue-700">Kontakt</h2>
            <div className="flex flex-col md:flex-row md:space-x-8">
                <div className="md:w-1/2 mb-8 md:mb-0 bg-white p-8 rounded-lg shadow-lg">
                    <h3 className="text-2xl font-semibold mb-6 text-blue-600">Skontaktuj się z nami</h3>
                    <p className="mb-4">Chętnie odpowiemy na Twoje pytania</p>
                    <p className="mb-2"><strong>Email:</strong> darex@darex.pl</p>
                    <p className="mb-2"><strong>Numer kontaktowy:</strong> 123 456 789</p>
                    <p className="mb-2"><strong>Nazwa firmy:</strong> Darex  Sp. z o.o. Sp. K.</p>
                    <p className="mb-2"><strong>Adres:</strong> ul. Kowalskiego 123, 80-462 Gdańsk</p>
                    <p className="mb-2"><strong>NIP:</strong> 123123123</p>
                    <p className="mb-8"><strong>REGON:</strong> 123123123</p>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2443.990231973439!2d18.600398815793336!3d54.37545630323613!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46fda35fa0a9ffcd%3A0x64dd162bc7d4f6f2!2sDywizjonu%20303%205K%2C%2080-462%20Gda%C5%84sk%2C%20Poland!5e0!3m2!1sen!2sus!4v1629835181237!5m2!1sen!2sus"
                        width="100%"
                        height="300"
                        allowFullScreen=""
                        loading="lazy"
                        title="Google Maps"
                        className="rounded-lg shadow-md"
                    ></iframe>
                </div>
                <div className="md:w-1/2 bg-white p-8 rounded-lg shadow-lg">
                    <h3 className="text-2xl font-semibold mb-6 text-blue-600">Napisz do nas</h3>
                    <form>
                        <div className="mb-6">
                            <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="name">Imię</label>
                            <input className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500" type="text" id="name" name="name" required />
                        </div>
                        <div className="mb-6">
                            <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="email">Email</label>
                            <input className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500" type="email" id="email" name="email" required />
                        </div>
                        <div className="mb-6">
                            <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="phone">Numer telefonu</label>
                            <input className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500" type="tel" id="phone" name="phone" required />
                        </div>
                        <div className="mb-6">
                            <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="message">Wiadomość</label>
                            <textarea className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500" id="message" name="message" rows="5" required></textarea>
                        </div>
                        <button className="w-full bg-blue-600 text-white py-2 rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500" type="submit">Wyślij</button>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default Contact;
