import React from 'react';

const CombinedSection = () => {
    return (
        <section className="container mx-auto py-10 px-4">
            <h1 className="text-4xl font-bold text-gray-800 mb-8 text-center">Nasze Realizacje</h1>
            <p className="text-lg text-gray-600 mb-8 text-center">
                Z dumą prezentujemy nasze dotychczasowe realizacje.<br/> Każdy projekt to wyjątkowe podejście i dbałość o najmniejszy detal, aby stworzyć wnętrza spełniające marzenia naszych klientów.
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
                {/* Showcase Item 1 */}
                <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                    <img src="https://www.kodo.pl/wp-content/uploads/DESKTOP-10.png" alt="Completed Project 1" className="w-full h-48 object-cover" />
                    <div className="p-6">
                        <h3 className="text-2xl font-bold text-gray-800 mb-2">Projekt Modernistyczny</h3>
                        <p className="text-gray-700 mb-4">Nowoczesne i eleganckie wnętrze, które łączy funkcjonalność z estetyką. Idealne dla miłośników minimalistycznych rozwiązań.</p>
                    </div>
                </div>
                {/* Showcase Item 2 */}
                <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                    <img src="https://www.kodo.pl/wp-content/uploads/DESKTOP-10.png" alt="Completed Project 2" className="w-full h-48 object-cover" />
                    <div className="p-6">
                        <h3 className="text-2xl font-bold text-gray-800 mb-2">Apartament w Gdańsku</h3>
                        <p className="text-gray-700 mb-4">Przestronne wnętrze z akcentami w stylu skandynawskim. Doskonałe dla tych, którzy cenią jasne i przestronne przestrzenie.</p>
                    </div>
                </div>
                {/* Showcase Item 3 */}
                <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                    <img src="https://www.kodo.pl/wp-content/uploads/DESKTOP-10.png" alt="Completed Project 3" className="w-full h-48 object-cover" />
                    <div className="p-6">
                        <h3 className="text-2xl font-bold text-gray-800 mb-2">Klasyczne Wnętrze</h3>
                        <p className="text-gray-700 mb-4">Elegancka i ponadczasowa aranżacja wnętrza, która łączy klasyczne elementy z nowoczesnymi detalami.</p>
                    </div>
                </div>
                {/* Add more items as needed */}
            </div>
            <div className="flex justify-center">
                <button className="mt-2 px-8 py-4 bg-blue-600 text-white hover:bg-blue-700 rounded text-xl" onClick={() => window.location.href = '/realizations'}>Pokaż Wszystko</button>
            </div>
        </section>
    );
};

export default CombinedSection;
